











































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import moment from 'moment'
@Component({})
/*
This component is used to display dates (e.g. start and end date of an event).
@group EVENTS
 */
export default class DateOutput extends Vue {
  // start date or single date if no end date is used
  @Prop({ required: true })
  startDate: Date

  // an optional end date to get a date range
  @Prop()
  endDate: Date

  getSameDate (startDate: Date, endDate: Date): boolean {
    return (startDate && endDate) ? !moment(startDate, 'DD-MM-YYYY').isSame(moment(endDate, 'DD-MM-YYYY')) : false
  }

  getDay (date: Date): number | string {
    return date ? moment(date).locale(this.$root.$i18n.locale).date() : ''
  }

  getMonth (date: Date): number | string {
    return date ? moment(date).locale(this.$root.$i18n.locale).format('MMM') : ''
  }

  getYear (date: Date): number | string {
    return date ? moment(date).locale(this.$root.$i18n.locale).format('YYYY') : ''
  }

  getWeekday (date: Date): number | string {
    return date ? moment(date).locale(this.$root.$i18n.locale).format('ddd') : ''
  }
}
