








































































































































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { Event } from '@/store/event/types'
import DateOutput from '@/components/events/DateOutput.vue'
import { parseURL, getTimeRange, shortenString } from '@/common/globals'
import SearchResult from '@/components/search-results/SearchResult.vue'

@Component({
  components: { SearchResult, DateOutput }
})
// Component for displaying events as a search result (used in dashboard, events page, kode metering for next evaluation events, ...)
// @group SEARCH RESULTS
export default class EventSearchResult extends Vue {
  // the event to display (check the type)
  @Prop() readonly event: Event
  // show delete button - used in combination with rights
  @Prop() readonly showDelete: boolean
  // show publish button - used in combination with rights
  @Prop() readonly showPublish: boolean
  // show edit button - used in combination with rights
  @Prop() readonly showEdit: boolean
  // show bookmark button - used in combination with rights
  @Prop() readonly showBookmark: boolean

  onEditClick (): void {
    this.$root.$emit('dismiss-alert')
    this.$emit('edit', this.event)
  }

  onDeleteClick (): void {
    this.$root.$emit('dismiss-alert')
    this.$emit('delete', this.event)
  }

  onActivateClick (): void {
    this.$root.$emit('dismiss-alert')
    this.$emit('activate', this.event)
  }

  onBookmarkClick (): void {
    this.$root.$emit('dismiss-alert')
    this.$emit('bookmark', this.event)
  }

  parseURL (link: string): string {
    return parseURL(link)
  }

  getTimeRange (startDate: Date, endDate: Date): string {
    return getTimeRange(startDate, endDate)
  }

  getShortString (description: string, length: number, suffix?: string) {
    return shortenString(description, length, suffix)
  }
}
